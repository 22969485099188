import UserControllerBase from '../base'
import type {
  NGetOrganizationsByUserId,
  NGetSession,
  NGetInviteData,
  NRegisterWithInvite,
  NInitiatePasswordRecovery,
  NFinalizePasswordRecovery,
  NGetTimeZones,
  NGetBasicInfo,
  NUpdateBasicInfo,
  NUploadImage,
  NGetInviteRegistrationInfo,
  NUpdatePassword,
  NSendTokenToChangePhone
} from './types'
import type { TResponseBase } from '~/services/MSAPI/types'

export default class UserControllerUser extends UserControllerBase {
  static readonly controllerPath = 'user'
  private readonly serviceUrl: string

  constructor() {
    super(UserControllerUser.controllerPath)
    this.serviceUrl = this.getFullServiceUrl()
  }

  async getSession(
    organizationId: string,
    params?: NGetSession.IParams,
  ): TResponseBase<NGetSession.IResponseData> {
    return await useFetchInstance(
      `${this.serviceUrl}/getSession/${organizationId}`,
      {
        method: 'GET',
        params,
      },
    )
  }

  async getOrganizationsByUserId(
    userId: string,
    params?: NGetOrganizationsByUserId.IParams,
  ): TResponseBase<NGetOrganizationsByUserId.IResponseData> {
    return await useFetchInstance(
      `${this.serviceUrl}/${userId}/organizations`,
      {
        method: 'GET',
        params,
      },
    )
  }

  async getInviteData(
    inviteId: string,
    params?: NGetInviteData.IParams,
  ): TResponseBase<NGetInviteData.IResponseData> {
    return await useFetchInstance(
      `${this.serviceUrl}/inviteregistrationinformation/${inviteId}`,
      {
        method: 'GET',
        params,
      },
    )
  }

  async registerWithInvite(inviteId: string, body: NRegisterWithInvite.IBody) {
    return await useFetchInstance(`${this.serviceUrl}/withInvite/${inviteId}`, {
      method: 'POST',
      body,
    })
  }

  getUserImageSmallURL(userId: string) {
    return `${this.serviceUrl}/profile/${userId}/image/small?v=0`
  }

  async initiatePasswordRecovery(query: NInitiatePasswordRecovery.IQuery) {
    return await useFetchInstance(
      `${this.serviceUrl}/initiate-password-recovery`,
      {
        method: 'POST',
        query: {
          email: query.email,
        },
      },
    )
  }

  async finalizePasswordRecovery(body: NFinalizePasswordRecovery.IBody) {
    return await useFetchInstance(
      `${this.serviceUrl}/finalize-password-recovery`,
      {
        method: 'POST',
        body,
      },
    )
  }

  async getTimeZones(): TResponseBase<NGetTimeZones.IResponseData[]> {
    return await useFetchInstance(`${this.serviceUrl}/timezones`, {
      method: 'GET',
    })
  }

  async getBasicInfo(
    userId: string,
  ): TResponseBase<NGetBasicInfo.IResponseData> {
    return await useFetchInstance(`${this.serviceUrl}/${userId}/basic-info`, {
      method: 'GET',
    })
  }

  async updateBasicInfo(
    userId: string,
    body: NUpdateBasicInfo.IBody,
  ): TResponseBase<NUpdateBasicInfo.IResponseData> {
    return await useFetchInstance(`${this.serviceUrl}/${userId}/basic-info`, {
      method: 'PUT',
      body,
    })
  }

  async uploadImage(body: NUploadImage.TBody) {
    return await useFetchInstance(`${this.serviceUrl}/upload-image`, {
      method: 'POST',
      body,
    })
  }

  async getInviteRegistrationInfo(
    inviteId: string,
  ): TResponseBase<NGetInviteRegistrationInfo.IResponseData> {
    return await useFetchInstance(
      `${this.serviceUrl}/inviteregistrationinformation/${inviteId}`,
      {
        method: 'GET',
      },
    )
  }

  async changeAccountEmail (userId: string, newEmail: string): TResponseBase<Boolean> {
    return await useFetchInstance(`${this.serviceUrl}/${userId}/email`, {
      method: 'PATCH',
      body: { email: newEmail}
    })
  }

  async updatePassword (formData: NUpdatePassword.IRequestData): TResponseBase<Boolean> {
    return await useFetchInstance(`${this.serviceUrl}/change-password`, {
      method: 'POST',
      body: formData
    })
  }

  async sendTokenToChangePhone (userId: string, formData: NSendTokenToChangePhone.IRequestTokenConfirmation) : TResponseBase<Boolean> {
    return await useFetchInstance(`${this.serviceUrl}/${userId}/phone`, {
      method: 'PATCH',
      body: formData
    })
  }

  async confirmChangeToken (userId: string, formData: NSendTokenToChangePhone.IRequestConfirmationPhoneChange) : TResponseBase<Boolean> {
    return await useFetchInstance(`${this.serviceUrl}/${userId}/phone/confirm`, {
      method: 'POST',
      body: formData
    })
  }

}
